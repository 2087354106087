import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import shakingHands from "../images/shakingHands.jpg";

import SEO from "../components/seo";


const Aboutus = ({  }) => {

  return (
    <Layout headerClass="bg-white relative">
      <SEO title="About Us" />  

      <div
        className="min-h-screen pt-12 sm:pt-16 md:pt-32 -mt-12 sm:-mt-8 md:-mt-24 lg:-mt-28 mb-20 bg-size-5/6 md:bg-size-4/5 lg:bg-size-2/3 bg-right-top bg-no-repeat flex flex-col items-center"
        
      >

          <div className="lg:px-64 md:px-32 sm:px-16">
            <img src={shakingHands} />
          </div>

        <div className="container w-3/4 md:w-1/2 pt-6 sm:pt-8 md:pt-16 font-serif font-hairline self-start">
          <h1 className="text-3xl md:text-5xl text-indigo-700 leading-tight">
            About Us
          </h1>
        </div>

        <div className="pt-4 sm:pt-8 md:pt-8 text-gray-800  self-center">
 <p>
We began trading in the Investigation Business in 2009 providing a service to Solicitors, Insurance Companies and other Corporate Clients.
All our Investigators are appropriately licensed and hold the necessary Insurances to protect themselves and our clients.
We have experienced Investigators with a Policing Background with:
</p>
<p></p>
<ul class="list-disc p-5">
<li>Major Crash Investigation.</li>
<li>General Investigation work dealing with Criminal Offences.</li>
<li>Police Prosecution.</li>
</ul>

<p>Our Investigators hold a Certificate 1V in Government Investigation.</p>
<p>ANZIFF CERTIFICATE Claims Investigation Standards and the General Insurance Code of Practice.</p>
<p>Workplace Training Category 1.</p>
<p>Investigating Professionalism AIPI. </p>

<div className="pt-4 sm:pt-8 md:pt-8 text-indigo-700 p-5 px-2">
<p>OUR MOTTO:   “INVESTIGATION WITHOUT PREJUDICE:”</p>
<p>OUR NAME COMES FROM THE TERM TO SPEAK WITH CANDOR:  “MEANING TO BE FRANK AND HONEST.”</p>
</div>
        </div>

        
      </div>
    </Layout>
  );
};


export default Aboutus;
